// extracted by mini-css-extract-plugin
export var address = "single-place-module--address--MxzL9";
export var addressWrapper = "single-place-module--addressWrapper--lgd3S";
export var card = "single-place-module--card--IXDbx";
export var contactDetails = "single-place-module--contactDetails--FfZvc";
export var contactIcon = "single-place-module--contactIcon--unR-B";
export var description = "single-place-module--description--muHHa";
export var descriptionWrapper = "single-place-module--descriptionWrapper--ZCYlX";
export var embedContainer = "single-place-module--embedContainer--UDbdj";
export var headerContent = "single-place-module--headerContent--hc4Nd";
export var headerContentWrapper = "single-place-module--headerContentWrapper--4qn3m";
export var headerImage = "single-place-module--headerImage--gl871";
export var headerWrapper = "single-place-module--headerWrapper--R9R81";
export var mapImage = "single-place-module--mapImage--lVoA3";
export var mapWrapper = "single-place-module--mapWrapper--iyLgI";
export var openingEven = "single-place-module--openingEven--Fdn0E";
export var openingHour = "single-place-module--openingHour--gK3ui";
export var reserveButton = "single-place-module--reserveButton--FS6Nc";
export var singleContactEntity = "single-place-module--singleContactEntity--EmX3Q";
export var wrapper = "single-place-module--wrapper--6NWZ0";