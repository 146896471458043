import * as React from "react";
import { Helmet } from "react-helmet";
import Grid from "@mui/material/Grid";
import * as styles from "./single-place.module.css";
import { parsePhoneNumber } from "awesome-phonenumber";
import Expense from "./single-place/SinglePlaceHeader/Expense";
import GoogleMapReact from "google-map-react";
import NavBar from "../components/NavBar/NavBar";
import { StaticImage } from "gatsby-plugin-image";
import { Button } from "@mui/material";

// markup
const SinglePlace = ({ pageContext: data }) => {
  function getSerialisedPhoneNumber(number) {
    return parsePhoneNumber(number, {
      regionCode: "GB",
    }).number.e164;
  }

  return (
    <>
      <NavBar />
      <div className={styles.wrapper}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Nuff | {data?.name} </title>
          <meta name="apple-itunes-app" content="app-id=1535231249" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, viewport-fit=cover"
          />
          <meta property="og:title" content={`${data.name}`} />
          <meta property="og:site_name" content="Nuff" />
          <meta
            property="og:url"
            content={`https://nuff.london/places/${data.slug}`}
          />
          <meta
            property="og:description"
            content={`${data.shortDescription}`}
          />
          <meta property="og:type" content="place" />
          <meta
            property="og:image"
            content={`https://res.cloudinary.com/dalyty5ns/image/upload/b_black,o_75,w_1200,h_630,c_fill/l_nuff-logo-stroke_dp4kcx/c_scale,w_0.15/fl_layer_apply,x_10,y_10/${data?.cloudinaryID}`}
          />
        </Helmet>

        <Grid container columnSpacing={{ xs: 0, sm: 2 }}>
          {/* Header */}
          <Grid item xs={12} className={styles.headerWrapper}>
            <img
              className={styles.headerImage}
              src={`https://res.cloudinary.com/dalyty5ns/image/upload/b_black,o_45/${data?.cloudinaryID}`}
            />
            <div className={styles.headerContentWrapper}>
              <div className={styles.headerContent}>
                <h1>{data.name}</h1>
                <h2>{data.shortDescription}</h2>
                <Expense number={data.expense} />
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={5}
            order={{ xs: 2, sm: 1 }}
            p={{ xs: 2, sm: 1 }}
          >
            <h3>Where is it?</h3>
            <div className={`${styles.card} ${styles.addressWrapper}`}>
              <div className={styles.mapWrapper}>
                <img
                  className={styles.mapImage}
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${data.latitude}%2C${data.longitude}&style=feature:poi|visibility:off&zoom=13&scale=2&size=300x150&maptype=roadmap&format=png&key=AIzaSyCw9pYYs27dmcCDDKZ7TpDNiq1k4KnH5qw&markers=size:mid%7Ccolor:0xe84118%7Clabel:N%7C${data.latitude}%2C${data.longitude}`}
                />
              </div>
              <div className={styles.address}>{data.address}</div>
              <Button
                target="_blank"
                rel="noreferrer"
                variant="outlined"
                href={`https://www.google.com/maps/place/?q=place_id:${data.googlePlaceID}`}
              >
                Open In Google Maps
              </Button>
            </div>
            <h3>Opening times</h3>
            <div className={styles.card}>
              <div>
                {data.openingTimes.opening_text.map((text, index) => {
                  return (
                    <div
                      className={`${
                        (index + 1) % 2 === 0 && styles.openingEven
                      } ${styles.openingHour}`}
                    >
                      {text}
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            p={{ xs: 2, sm: 0 }}
            order={{ xs: 1, sm: 2 }}
          >
            <h3>Description</h3>
            <div className={`${styles.card} ${styles.descriptionWrapper}`}>
              <div className={styles.description}>{data.description}</div>
            </div>
            <h3>Contact</h3>
            <div className={`${styles.card}`}>
              <div className={styles.contactDetails}>
                {data?.website && (
                  <div className={styles.singleContactEntity}>
                    <StaticImage
                      className={styles.contactIcon}
                      src="../images/websiteIcon.png"
                      alt="globe icon"
                      placeholder="none"
                    />
                    <a target="_blank" rel="noreferrer" href={data.website}>
                      Website
                    </a>
                  </div>
                )}

                {data?.phone?.length > 0 && (
                  <div className={styles.singleContactEntity}>
                    <StaticImage
                      className={styles.contactIcon}
                      src="../images/phoneIcon.png"
                      alt="globe icon"
                      placeholder="none"
                    />
                    <a href={`tel:${getSerialisedPhoneNumber(data?.phone)}`}>
                      {data.phone}
                    </a>
                  </div>
                )}

                {data?.instagramID.length > 0 && (
                  <div className={styles.singleContactEntity}>
                    <StaticImage
                      className={styles.contactIcon}
                      src="../images/instaIcon.png"
                      alt="globe icon"
                      placeholder="none"
                    />
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${`https://instagram.com/${data.instagramID}`}`}
                    >{`@${data.instagramID}`}</a>
                  </div>
                )}
              </div>
            </div>

            {data?.bookingURL && (
              <>
                <h3>Reservations</h3>
                <div className={styles.embedContainer}>
                  <Button
                    href={data.bookingURL}
                    variant="outlined"
                    className={styles.reserveButton}
                  >
                    Make a reservation
                  </Button>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export function Head() {
  return <title>Hello World</title>;
}

export default SinglePlace;
