import React from "react";
import * as styles from "./Expense.module.css";

const Expense = ({ number }) => {
  function renderExpense() {
    const MAX_EXPENSE = 4;

    const ExpenseArray = [];

    for (let index = 0; index < MAX_EXPENSE; index++) {
      const isActive = index < number;

      ExpenseArray.push(
        <span className={`${styles.expense} ${isActive && styles.isActive}`}>
          £
        </span>
      );
    }

    return ExpenseArray;
  }

  return <div>{renderExpense()}</div>;
};

export default Expense;
