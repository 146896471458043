import React from "react";
import * as styles from "./NavBar.module.css";
import { Link } from "gatsby";

const NavBar = () => {
  return (
    <div className={styles.NavBar}>
      <Link to="/" className={styles.logo}>
        Nuff
      </Link>
    </div>
  );
};

export default NavBar;
